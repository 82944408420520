

<template>
    <div>

        <div v-if="!loadingPath && videoFilePath != null && videoFilePath.length > 0" style="min-height: 100vh;  background-color: #444444;">
            
            <a-row type="flex" justify="center" align="center" style="padding-top: 15%;">
                <a-col :span="24" :md="16" :lg="16" class="text-center justify-items-center float-center">
                    <video-player :options="videoOptions"/>
                </a-col>
            </a-row>
            
        </div>

        <div v-if="!loadingPath && message.length > 0 && (videoFilePath == null || videoFilePath == 'null' || videoFilePath.length == 0)">

            <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" class="text-center justify-items-center">

                    <label>{{ message }}</label>

                </a-col>

            </a-row>
        </div>
        
        <div v-if="loadingPath">
            <a-row type="flex" :gutter="24" justify="space-around" align="middle" class="text-center" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

                    <double-bounce class="" ></double-bounce>

                </a-col>

            </a-row>
        </div>

    </div>
</template>
    
    <script>
    import { DoubleBounce } from 'vue-loading-spinner';
    import VideoPlayer from './VideoModel.vue';
    import 'video.js/dist/video-js.css'
    
    export default {
    components: {
        DoubleBounce,
        VideoPlayer
    },
    data() {
        return {
            
            videoFilePath: null,
            loadingPath: false,
            message: '',
            videoOptions: null,
            
        };
    },
    created() {
        this.getFilePath()
    },
    methods: {
        async getFilePath() {

            this.loadingPath = true;

            const videoFileUrl = await localStorage.getItem("videoFilePath");
            const fileName = await localStorage.getItem("videoFileName");

            this.videoOptions = {
                autoplay: false,
                controls: true,
                language: 'en',
                sources: [
                    {
                        src: videoFileUrl,
                        // type: 'video/mp4'
                    }
                ],
                // poster: fileName,
                width: 680,
                height: 380
            }

            this.videoFilePath = videoFileUrl;
            this.loadingPath = false;

        }
    }
    };
    </script>